import React, { useState } from "react";
import Select from "react-select";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Dialog, Tab, Typography } from "@mui/material";
import { useQuery } from "react-query";
import moment from "moment";
import SingleAttendenceDetails from "../../OfficeAttendence/SingleAttendenceDetails";
import { CustomZoomTransition } from "../../../components/CustomZoomTransition";
import { customSelectStyle } from "../../../utility/styles";
import { useFetchAPI } from "../../../hooks/useFetchAPI";

const DashboardEmployeeAttendance = ({ branches }) => {
  const { getFetcher } = useFetchAPI();
  const allBranches = branches?.data;
  const [attQuery, setAttQuery] = useState("check-in-pending");
  const [selectedOption, setSelectedOption] = useState(allBranches[0]?.id);
  const [keyword, setKeyword] = useState("");
  const [open, setOpen] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState({ id: "", name: "" });

  const { data, status } = useQuery({
    queryKey: ["currentDate-attendance", attQuery, selectedOption, keyword],
    queryFn: () =>
      getFetcher(
        `dashboard/attendances?date=${moment(new Date()).format(
          "YYYY-MM-DD"
        )}&attendanceStatus=${attQuery}&branchId=${selectedOption}&name=${keyword}`
      ),
    refetchOnMount: "always",
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  if (status === "loading") return <>Loading</>;
  if (status === "error") return <>Something went wrong..!!</>;

  const allEmployees = data?.data;

  const selectOptions = allBranches?.map((br) => ({
    label: (
      <Box
        sx={{
          padding: "5px 8px",
        }}
      >
        <Typography sx={{ fontSize: "12px" }}>{br?.branchName}</Typography>
      </Box>
    ),
    value: br?.id,
  }));

  const handleSelectChange = (option) => {
    setSelectedOption(option?.value);
  };

  return (
    <Box
      sx={{
        ...boxStyle,
        p: 0,
        ".MuiTabScrollButton-root": { width: "16px" },
        ".css-15d83qz-MuiButtonBase-root-MuiTab-root.Mui-selected": {
          color: "var(--primary-color)",
        },
        ".MuiTabs-indicator": { bgcolor: "var(--primary-color)" },
        ".MuiTab-root": { px: "22px" },
        ".MuiTabs-scroller": { bgcolor: "white", borderRadius: "5px 5px 0 0" },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography className="page-title" mb={1.5}>
          Select Office
        </Typography>

        <Select
          onChange={handleSelectChange}
          styles={customSelectStyle}
          classNamePrefix="select"
          isSearchable={false}
          options={selectOptions}
          placeholder={
            <Typography
              sx={{ fontSize: "12px", pl: "5px", color: "var(--text-color)" }}
            >
              Select Branch
            </Typography>
          }
          defaultValue={selectOptions[0]}
          value={selectOptions.find(
            (option) => option.value === selectedOption
          )}
        />
      </Box>

      <TabContext value={attQuery}>
        <TabList
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          onChange={(_, newValue) => setAttQuery(newValue)}
          aria-label="lab API tabs example"
        >
          {attendenceQueries.map((query, index) => (
            <Tab
              key={index}
              sx={{
                fontSize: "12px",
                textTransform: "none",
                color:
                  attQuery === query.value ? "var(--primary-color)" : undefined,
              }}
              label={query?.label}
              value={query?.value}
            />
          ))}
        </TabList>

        {attendenceQueries.map((query, index) => (
          <TabPanel
            key={index}
            sx={{ height: "100%", pt: 0, px: 2, pb: 2 }}
            value={query.value}
          >
            <Box sx={{ bgcolor: "white", borderRadius: "0 0 5px 5px" }}>
              <Box sx={{ p: 2, pb: 0 }}>
                <input
                  type="text"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  placeholder="Search Employee"
                  className="dashboard_employee_search_input"
                />
              </Box>

              <Box
                sx={{
                  height: "calc(1220px - 470px)",
                  overflowY: "scroll",
                }}
              >
                {allEmployees.length > 0 ? (
                  <>
                    {allEmployees.map((employee, i) => (
                      <Box
                        key={i}
                        sx={{
                          p: 2,
                          borderTop: i !== 0 && "1px solid var(--border-color)",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            ...flexCenter,
                            backgroundColor: "var(--primary-color)",
                            height: "40px",
                            width: "40px",
                            borderRadius: "50%",
                            fontSize: "14px",
                            color: "white",
                          }}
                        >
                          {
                            employee?.employeeName
                              .split(" ")
                              .filter(
                                (_, i, arr) => i === 0 || i === arr.length - 1
                              )[0][0]
                          }
                          {
                            employee?.employeeName
                              .split(" ")
                              .filter(
                                (_, i, arr) => i === 0 || i === arr.length - 1
                              )[1][0]
                          }
                        </Box>

                        <Box sx={{ width: "calc(100% - 55px)" }}>
                          <Box
                            sx={{
                              ...flexCenter,
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: 600 }}
                            >
                              {employee?.employeeName}
                            </Typography>
                            <Typography
                              onClick={() => {
                                setOpen(true);
                                setCurrentEmployee({
                                  id: employee?.employeeId,
                                  name: employee?.employeeName,
                                });
                              }}
                              sx={{
                                fontSize: "12px",
                                color: "var(--primary-color)",
                                cursor: "pointer",
                              }}
                            >
                              View
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              color: "var(--primary-color)",
                              width: "95%",
                            }}
                            noWrap
                          >
                            {employee?.designation},{" "}
                            <span style={{ color: "var(--text-medium)" }}>
                              {employee?.deptName}
                            </span>
                          </Typography>
                          <Box
                            sx={{
                              ...flexCenter,
                              justifyContent: "start",
                              gap: "12px",
                            }}
                          >
                            <Typography sx={{ fontSize: "11px" }}>
                              Check In - {employee?.checkInStatus || "....."}
                            </Typography>
                            <Typography sx={{ fontSize: "11px" }}>
                              Check Out - {employee?.checkOutStatus || "....."}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </>
                ) : (
                  <Box
                    sx={{ mt: 5, display: "flex", justifyContent: "center" }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>
                      No employee found
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </TabPanel>
        ))}
      </TabContext>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={CustomZoomTransition}
        maxWidth="md"
        fullWidth
      >
        <Box sx={{ p: 3, minHeight: "450px" }}>
          <SingleAttendenceDetails
            currentDate={new Date()}
            employeeId={currentEmployee?.id}
            employeeName={currentEmployee?.name}
            setOpen={setOpen}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

const boxStyle = { borderRadius: "10px", bgcolor: "#F4F5F7", p: 2 };
const flexCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const attendenceQueries = [
  {
    label: "Pending",
    value: "check-in-pending",
  },
  {
    label: "Check In",
    value: "check-in-on-time",
  },
  {
    label: "Check In Late",
    value: "check-in-late",
  },
  {
    label: "Check Out Early",
    value: "check-out-early",
  },
  {
    label: "Check Out",
    value: "check-out-on-time",
  },
];

export default DashboardEmployeeAttendance;
