import React from "react";
import { useAuth } from "../contex/AuthProvider";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const localApi = "http://192.168.1.103:5000/api/company/";
// const localApiV2 = "http://192.168.1.103:5000/api/v2/company/";
const liveApi = "https://flyfarerp-413411.de.r.appspot.com/api/company/";
// const liveApiV2 = "https://flyfarerp-413411.de.r.appspot.com/api/v2/company/";

export const useFetchAPI = () => {
  const { user: userFormContext } = useAuth();
  const userFromLocalStorage = secureLocalStorage.getItem("admin-info");

  const headers = {
    Authorization: `Bearer ${
      userFormContext?.accessToken || userFromLocalStorage?.accessToken
    }`,
    "Content-Type": "application/json",
  };

  const getFetcher = async (endPoint) => {
  
    const url = liveApi + endPoint;

    try {
      const reponse = await axios.get(url, { headers });

      return reponse?.data;
    } catch (e) {
      return e?.response?.data;
    }
  };

  return { getFetcher };
};
