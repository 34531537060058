import {
  Box,
  Button,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
  Zoom,
  Dialog,
} from "@mui/material";
import { addButton } from "../../utility/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

import moment from "moment";

import Swal from "sweetalert2";
import { styled } from "@mui/system";
import APILoader from "../../components/APILoader/APILoader";
import { selectStyle } from "../../components/Holiday/style";

const headers = [
  "Name",
  "Check In ",
  "Check Out",
  "Break Time",
  "Buffer Time",
  "Department",
  "Office",
  "Action",
];

const CustomSelect = styled(Select)({
  width: "450px",
  minWidth: "200px",
  "& .MuiSelect-select": {
    display: "flex",
    flexGrow: 1,
  },
});

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  color: "black",
  "&.Mui-selected": {
    backgroundColor: "var(--primary-color)",
    color: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "var(--primary-color)",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const Roster = () => {
  const navigate = useNavigate();
  const [roaster, setRoaster] = useState([]);
  const [formInfo, setFormInfo] = useState({});
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedWeekends, setSelectedWeekends] = useState([]);
  const [Id, setId] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [allBranch, setAllBranch] = useState([]);
  const [allDept, setAllDept] = useState([]);
  const [selectedDept, setSelectedDept] = useState({
    id: "",
    deptName: "",
  });
  const [selectedBranch, setSelectedBranch] = useState({
    id: "",
    branchName: "",
  });

  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "checkIn" || name === "checkOut") {
      // Format time for checkIn and checkOut fields
      let formatTime = moment(value, "HH:mm").format("HH:mm:ss");

      const timeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;

      // Check if the input value matches the time format
      if (!timeRegex.test(formatTime)) {
        // Invalid time format, do not update state
        return;
      }

      // Update formInfo with the formatted time value
      setFormInfo((prevState) => ({
        ...prevState,
        [name]: formatTime,
      }));
    } else {
      // Update roasterName directly with the input value
      setFormInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleOpen = (data) => {
    setSelectedWeekends([...selectedWeekends, data?.weekends]);
    setOpen(true);
    setFormInfo(data);
    setId(data?.id);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `https://flyfarerp-413411.de.r.appspot.com/api/v2/company/roasters/${Id}`;
    // const url = `http://192.168.1.103:5000/api/company/roasters/${Id}`;

    const body = JSON.stringify({
      // checkIn: formInfo.checkIn,
      // checkOut: formInfo.checkOut,
      roasterName: formInfo.roasterName,
      // weekends: selectedWeekends.join(","),
      bufferTime: parseInt(formInfo?.bufferTime),
      breakTime: parseInt(formInfo?.breakTime),
    });

    try {
      setLoading(true); // Move setLoading here

      const response = await fetch(url, {
        method: "PATCH",
        body: body,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data?.success === true) {
        Swal.fire({
          icon: "success",
          title: data?.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          navigate("/dashboard/roster");
          window.location.reload();
        });
      } else {
        throw new Error(data?.message);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: err.message || "Something went wrong",
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Try again",
      }).then(function () {
        navigate("/dashboard/roster");
      });
    } finally {
      setLoading(false); // Ensure setLoading is set to false even in case of error
    }

    handleClose();
    e.target.reset();
  };

  useEffect(() => {
    setIsLoaded(false);
    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/v2/company/roasters`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        setIsLoaded(true);
        setRoaster(data?.data);
      })
      .catch((error) => {
        setIsLoaded(true);
        console.error("Error fetching departments:", error);
      });
  }, [token]);

  useEffect(() => {
    setIsLoaded(false);
    const url =
      "https://flyfarerp-413411.de.r.appspot.com/api/company/branches";

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        setIsLoaded(true);
        setAllBranch(data?.data);
        setSelectedBranch({
          id: data?.data[0]?.id,
          branchName: data?.data[0]?.branchName,
        });
      })
      .catch((error) => {
        setIsLoaded(true);
        console.error("Error fetching departments:", error);
      });
  }, [token]);

  useEffect(() => {
    setIsLoaded(false);
    const url =
      "https://flyfarerp-413411.de.r.appspot.com/api/company/departments";

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        setIsLoaded(true);
        setAllDept(data?.data);
        setSelectedDept({
          id: data?.data[0]?.id,
          deptName: data?.data[0]?.deptName,
        });
      })
      .catch((error) => {
        setIsLoaded(true);
        console.error("Error fetching departments:", error);
      });
  }, [token]);

  // console.log(selectedWeekends)

  const filterRoaster = roaster.filter(
    (s) => s.branchId === selectedBranch?.id && s.deptId === selectedDept?.id
  );

  // console.log(selectedBranch, selectedDept);

  const handleDeptChange = (event) => {
    setSelectedDept({
      id: event.target.value,
      deptName:
        allDept.find((dept) => dept.id === event.target.value)?.deptName || "",
    });
  };

  const handleBranchChange = (event) => {
    setSelectedBranch({
      id: event.target.value,
      branchName:
        allBranch.find((br) => br.id === event.target.value)?.branchName || "",
    });
  };

  const handleRemoveRoster = (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to remove ?",
      confirmButtonColor: "var(--primary-color)",
      confirmButtonText: "Ok",
    }).then(async function (res) {
      if (res.isConfirmed) {
        // const url = `http://192.168.1.103:5000/api/v2/company/roasters/status/${id}`;
        const url = `https://flyfarerp-413411.de.r.appspot.com/api/v2/company/roasters/status/${id}`;

        try {
          setLoading(true);

          const response = await fetch(url, {
            method: "PATCH",
            body: JSON.stringify({ status: "inactive" }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          const data = await response.json();

          if (data?.success === true) {
            Swal.fire({
              icon: "success",
              title: data?.message,
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(function () {
              navigate("/dashboard/roster");
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: "error",
              title: data?.error?.message || "Something went wrong",
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Try again",
            });
          }
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: err.message || "Something went wrong",
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Try again",
          });
        } finally {
          setLoading(false); // Ensure setLoading is set to false even in case of error
        }
      }
    });
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: "25px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="title" className="page-title">
          Roster List
        </Typography>

        <Box sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
          <select
            type="text"
            name="deptId"
            id="deptId"
            required
            style={selectStyle}
            onChange={handleBranchChange}
          >
            {allBranch.map((data, index) => (
              <option
                selected={data.id === selectedBranch.id}
                key={index}
                value={data.id}
              >
                {data.branchName}
              </option>
            ))}
          </select>
          <select
            type="text"
            name="deptId"
            id="deptId"
            required
            style={selectStyle}
            onChange={handleDeptChange}
          >
            {allDept.map((data, index) => (
              <option
                selected={data.id === selectedDept.id}
                key={index}
                value={data.id}
              >
                {data.deptName}
              </option>
            ))}
          </select>

          <Button
            onClick={() => navigate("/dashboard/roster/addRoster")}
            sx={{ ...addButton, ml: 2 }}
          >
            Add Roster
          </Button>
        </Box>
      </Box>

      {isLoaded ? (
        <>
          {/*------------- Page Title -------------*/}

          {/*------------- Page Content -------------*/}
          <Box mt={3}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 1450 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {headers.map((header, i) => (
                      <TableCell key={i}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterRoaster.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell> {data?.roasterName} </TableCell>
                      <TableCell>
                        {" "}
                        {moment(data?.checkIn, "HH:mm:ss").format(
                          "h:mm A"
                        )}{" "}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {moment(data?.checkOut, "HH:mm:ss").format(
                          "h:mm A"
                        )}{" "}
                      </TableCell>
                      <TableCell> {data?.breakTime} </TableCell>
                      <TableCell> {data?.bufferTime} </TableCell>
                      <TableCell> {data?.deptName} </TableCell>
                      <TableCell> {data?.branchName} </TableCell>
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Button
                            variant="contained"
                            sx={{
                              marginLeft: "10px",
                              background: "var(--primary-color)",
                              "&:hover": {
                                background: "var(--primary-color)",
                              },
                              textTransform: "capitalize",
                            }}
                            onClick={() => {
                              handleOpen(data);
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "12px", color: "white" }}
                            >
                              Update
                            </Typography>
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              marginLeft: "10px",
                              bgcolor: "var(--text-medium)",
                              ":hover": {
                                bgcolor: "var(--text-medium)",
                              },
                              textTransform: "capitalize",
                            }}
                            onClick={() => handleRemoveRoster(data?.id)}
                          >
                            <Typography
                              sx={{ fontSize: "12px", color: "white" }}
                            >
                              Remove
                            </Typography>
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <Box sx={{ height: "calc(100vh - 200px)" }}>
          <APILoader />
        </Box>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <Box
          sx={{
            input: {
              border: "none",
              backgroundColor: "var( --input-bgcolor)",
              padding: "10px 8px 10px 8px",
              width: "100%",
            },
            p: 3,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: "600px",
              fontSize: "22px",
              color: "#222222",
            }}
            mb={2}
          >
            Update Roaster Information
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box className="input-container">
              <label htmlFor="salary" className="input-label">
                <span>Roaster Name</span>
              </label>
              <input
                value={formInfo?.roasterName}
                onChange={handleChange}
                name="roasterName"
                className="input-field"
                type="text"
                placeholder="Raoster Name"
                required
              />
            </Box>

            {/* <Box className="input-container">
              <label htmlFor="salary" className="input-label">
                <span>Check In</span>
              </label>
              <TextField
                type="time"
                name="checkIn"
                value={formInfo?.checkIn}
                onChange={handleChange}
                fullWidth
                sx={{
                  fontSize: "14px",
                  ml: "10px",
                }}
                required
              />
            </Box>

            <Box className="input-container">
              <label htmlFor="salary" className="input-label">
                <span>Check Out</span>
              </label>
              <TextField
                type="time"
                name="checkOut"
                value={formInfo?.checkOut}
                onChange={handleChange}
                fullWidth
                sx={{
                  fontSize: "14px",
                  ml: "10px",
                }}
                required
              />
            </Box> */}

            <Box className="input-container">
              <label htmlFor="salary" className="input-label">
                <span>Buffer Time</span>
              </label>
              <TextField
                type="number"
                fullWidth
                value={formInfo?.bufferTime}
                onChange={(e) => {
                  if (e.target.value < 31 && e.target.value >= 0) {
                    handleChange(e);
                  }
                }}
                name="bufferTime"
                sx={{
                  fontSize: "14px",
                  ml: "10px",
                }}
              />
            </Box>

            <Box className="input-container">
              <label htmlFor="salary" className="input-label">
                <span>Break Time</span>
              </label>
              <TextField
                type="number"
                fullWidth
                value={formInfo?.breakTime}
                onChange={(e) => {
                  if (e.target.value < 61 && e.target.value >= 0) {
                    handleChange(e);
                  }
                }}
                name="breakTime"
                sx={{
                  fontSize: "14px",
                  ml: "10px",
                }}
              />
            </Box>

            {/* <Box className="input-container">
              <label htmlFor="salary" className="input-label">
                <span>Select Weekends</span>
              </label>
              <CustomSelect
                multiple
                value={selectedWeekends}
                onChange={handleWeekendChange}
                input={<OutlinedInput label="Multiple Select" />}
                sx={{ width: "100%" }}
              >
                {weekends.map((name) => (
                  <CustomMenuItem key={name} value={name}>
                    {name}
                  </CustomMenuItem>
                ))}
              </CustomSelect>
            </Box> */}

            <Box sx={{ textAlign: "end" }}>
              <Button
                sx={{
                  fontFamily: "poppins",
                  fontWeight: "400",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  borderRadius: "2px",
                  bgcolor: "var(--text-medium)",
                  ":hover": {
                    bgcolor: "var(--text-medium)",
                  },
                  color: "#FFFFFF",
                  mt: 3,
                }}
                type="submit"
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </Box>
  );
};

export default Roster;
