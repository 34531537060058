import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Swal from "sweetalert2";
import UpdatePersonalInfo from "./UpdatePersonalInfo";
import UpdateContactInfo from "./UpdateContactInfo";
import UpdtaeOfficialInfo from "./UpdtaeOfficialInfo";
import UpdateEquipment from "./UpdateEquipment";
import UpdateSalaryInfo from "./UpdateSalaryInfo";

const UpdateEmployeeTabs = ({ formData, setFormData, data }) => {
  const [value, setValue] = useState("1");
  const user = secureLocalStorage.getItem("admin-info");
  const navigate = useNavigate();
  const token = user?.accessToken;
  console.log(token);
  console.log(data);

  const Id = data?.id;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleNidImg = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormData({
          ...formData,
          nid: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleProfileImg = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormData({
          ...formData,

          image: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleNextTab = () => {
    setValue((prevValue) =>
      parseInt(prevValue) >= 5 ? "submit" : String(parseInt(prevValue) + 1)
    );
  };
  const handlePreviousTab = () => {
    setValue((prevValue) =>
      parseInt(prevValue) <= 1 ? "5" : String(parseInt(prevValue) - 1)
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formDataToSend = new FormData();

      const formKeys = Object.keys(formData);

      if (!formKeys.length) {
        console.log("No Data Found");
        return;
      }
      formKeys.forEach((formKey) => {
        if (formData[formKey]) {
          formDataToSend.append(formKey, formData[formKey]);
        }
      });

      const response = await fetch(
        `https://flyfarerp-413411.de.r.appspot.com/api/company/employees/${Id}`,
        {
          method: "PATCH",
          body: formDataToSend,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form data");
      }
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Employee Add Successfully!",
      }).then(() => {
        navigate("/dashboard/employee");
      });
    } catch (error) {
      console.error("Error submitting form data:", error.message);
      // Show error message using SweetAlert
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const handleFormChange = (event) => {
    console.log(event);
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChange}
            indicatorColor="none"
            textColor="white"
            aria-label="home-tab"
            sx={{ display: "flex", borderBottom: "none" }}
          >
            <Tab sx={tabStyle} label="Update Personal Information" value="1" />
            <Tab
              label="Update Personal Contact Information"
              value="2"
              sx={tabStyle}
            />
            <Tab label="Update Official Information" value="3" sx={tabStyle} />
            <Tab label="Update Salary Information" value="4" sx={tabStyle} />
            <Tab label="Update Equipment Information" value="5" sx={tabStyle} />
          </TabList>
        </Box>
        <TabPanel value="1">
          <UpdatePersonalInfo
            formData={formData}
            setFormData={setFormData}
            handleFormChange={handleFormChange}
            handleNidImg={handleNidImg}
            handleProfileImg={handleProfileImg}
          />
        </TabPanel>
        <TabPanel value="2">
          <UpdateContactInfo
            formData={formData}
            handleFormChange={handleFormChange}
          />
        </TabPanel>
        <TabPanel value="3">
          <UpdtaeOfficialInfo
            formData={formData}
            handleFormChange={handleFormChange}
          />
        </TabPanel>
        <TabPanel value="4">
          <UpdateSalaryInfo
            formData={formData}
            handleFormChange={handleFormChange}
          />
        </TabPanel>
        <TabPanel value="5">
          <UpdateEquipment
            formData={formData}
            handleFormChange={handleFormChange}
            handleSubmit={handleSubmit}
          />
        </TabPanel>
      </TabContext>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box
          className="justify_between align_center"
          sx={{
            gap: 1,
          }}
        >
          {value !== "1" && (
            <Button variant="primaryBtn" onClick={handlePreviousTab}>
              Back
            </Button>
          )}
          <Button
            variant="primaryBtn"
            onClick={value === "5" ? handleSubmit : handleNextTab}
          >
            {value === "5" ? "Submit " : "Next"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const tabStyle = {
  height: "30px",
  minHeight: "10px",
  paddingX: 1,
  fontSize: "12px",
  textTransform: "Capitalize",
  "&.Mui-selected": {
    backgroundColor: "var(--primary-color)",
    color: "white",
    opacity: "1",
  },
  mr: 1,
};

export default UpdateEmployeeTabs;
