import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";

const shiftHeader = [
  "Applicable Day",
  "Shift Count",
  "Roster Name",
  "Check In",
  "Check Out",
  "Shift Location",
  "Break Time",
  "Buffer Time",
];

const EmployeeShifts = ({ currentData }) => {
  const user = secureLocalStorage.getItem("admin-info");
  const [allShifts, setAllShifts] = useState([]);

  useEffect(() => {
    const token = user?.accessToken;
    fetch(
      `https://flyfarerp-413411.de.r.appspot.com/api/company/shifts/employees?shiftStatus=active&employeeId=${currentData?.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAllShifts(data?.data[0]?.allDays);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, []);



  return (
    <Box>
      <Typography
        variant="title"
        className="page-title"
        sx={{ fontSize: "20px" }}
      >
        {currentData?.firstname} {currentData?.lastname} All Shifts
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700, mt: 3 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {shiftHeader.map((header, i) => (
                <TableCell key={i}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allShifts?.map((day, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{day?.applicableDay}</TableCell>
                  <TableCell>
                    {day?.shifts?.map((shift, ind, arr) => {
                      return (
                        <span
                          key={ind}
                          style={{
                            display: ind === 0 ? "block" : "none",
                            borderTop:
                              arr.length > 0 &&
                              ind !== 0 &&
                              "1px solid var(--border-color)",
                          }}
                        >
                          {shift?.dayState !== "weekend" && day?.shifts.length}
                        </span>
                      );
                    })}
                  </TableCell>
                  <TableCell>
                    {day?.shifts?.map((shift, ind, arr) => {
                      return (
                        <span
                          key={ind}
                          style={{
                            display: "block",
                            borderTop:
                              arr.length > 0 &&
                              ind !== 0 &&
                              "1px solid var(--border-color)",
                          }}
                        >
                          {shift?.roaster?.roasterName}

                          {shift?.dayState === "weekend" && shift?.dayState}
                        </span>
                      );
                    })}
                  </TableCell>
                  <TableCell>
                    {day?.shifts?.map((shift, ind, arr) => {
                      return (
                        <span
                          key={ind}
                          style={{
                            display: "block",
                            borderTop:
                              arr.length > 0 &&
                              ind !== 0 &&
                              "1px solid var(--border-color)",
                          }}
                        >
                          {shift?.roaster?.checkIn && (
                            <>
                              {moment(
                                shift?.roaster?.checkIn,
                                "hh:mm:ss"
                              ).format("hh:mm A")}
                            </>
                          )}
                        </span>
                      );
                    })}
                  </TableCell>
                  <TableCell>
                    {day?.shifts?.map((shift, ind, arr) => {
                      return (
                        <span
                          key={ind}
                          style={{
                            display: "block",
                            borderTop:
                              arr.length > 0 &&
                              ind !== 0 &&
                              "1px solid var(--border-color)",
                          }}
                        >
                          {shift?.roaster?.checkOut && (
                            <>
                              {moment(
                                shift?.roaster?.checkOut,
                                "hh:mm:ss"
                              ).format("hh:mm A")}
                            </>
                          )}
                        </span>
                      );
                    })}
                  </TableCell>
                  <TableCell>
                    {day?.shifts?.map((shift, ind, arr) => {
                      return (
                        <span
                          key={ind}
                          style={{
                            display: "block",
                            borderTop:
                              arr.length > 0 &&
                              ind !== 0 &&
                              "1px solid var(--border-color)",
                          }}
                        >
                          {shift?.roaster?.checkOut}
                        </span>
                      );
                    })}
                  </TableCell>
                  <TableCell>
                    {day?.shifts?.map((shift, ind, arr) => {
                      return (
                        <span
                          key={ind}
                          style={{
                            display: "block",
                            borderTop:
                              arr.length > 0 &&
                              ind !== 0 &&
                              "1px solid var(--border-color)",
                          }}
                        >
                          {shift?.roaster?.breakTime}
                        </span>
                      );
                    })}
                  </TableCell>
                  <TableCell>
                    {day?.shifts?.map((shift, ind, arr) => {
                      return (
                        <span
                          key={ind}
                          style={{
                            display: "block",
                            borderTop:
                              arr.length > 0 &&
                              ind !== 0 &&
                              "1px solid var(--border-color)",
                          }}
                        >
                          {shift?.roaster?.bufferTime}
                        </span>
                      );
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EmployeeShifts;
