import { Box, Typography } from "@mui/material";
import { Link, NavLink, Outlet } from "react-router-dom";
import List from "@mui/material/List";
import MuiDrawer from "@mui/material/Drawer";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Header from "../Header/Header";
import { ReactComponent as LogoIcon } from "../../assets/svg/logo.svg";
import { ReactComponent as DashboardIcon } from "../../assets/svg/dashboard.svg";
import { ReactComponent as EmployeeIcon } from "../../assets/svg/employee.svg";
import { ReactComponent as SalaryIcon } from "../../assets/svg/salary.svg";
import { ReactComponent as DepartmentIcon } from "../../assets/svg/department.svg";
import { ReactComponent as ManageIcon } from "../../assets/svg/setting.svg";
import { ReactComponent as LogoutIcon } from "../../assets/svg/logout.svg";
import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";
import { ReactComponent as ActivityIcon } from "../../assets/svg/activity.svg";
import { ReactComponent as OvertimeIcon } from "../../assets/svg/overtime.svg";
import AdminAuthentication from "../../pages/AdminAuth/AdminAuthentication";
import secureLocalStorage from "react-secure-storage";
import AlignHorizontalRightIcon from "@mui/icons-material/AlignHorizontalRight";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

const sidebarLinks = {
  color: "var(--text-medium)",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  margin: "28px 0",
  fontSize: "14px",
};

const drawerWidth = 200;
const openedMixin = (theme) => {
  return {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  };
};

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "0px",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DashboardSidebar = () => {
  const [open, setOpen] = useState(false);
  const [manage, setManage] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [facilities, setFacilities] = useState(false);
  const [rules, setRules] = useState(false);
  const [attendance, setAttendance] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const user = secureLocalStorage.getItem("admin-info");

  const toggleHtmlOverflow = () => {
    const htmlElement = document.querySelector("html");
    if (htmlElement) {
      htmlElement.style.overflow = !open ? "hidden" : "auto";
    }
    setOpen(!open);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if (index === 1) {
      setOpen(true);
      setFacilities(false);
      setManage(!manage);
      setRules(false);
      setAttendance(false);
    } else if (index === 2) {
      setOpen(true);
      setFacilities(!facilities);
      setManage(false);
      setRules(false);
      setAttendance(false);
    } else if (index === 3) {
      setOpen(true);
      setFacilities(false);
      setManage(false);
      setRules(!rules);
      setAttendance(false);
    } else if (index === 4) {
      setOpen(true);
      setFacilities(false);
      setManage(false);
      setRules(false);
      setEmployee(!employee);
      setAttendance(false);
    } else if (index === 5) {
      setOpen(true);
      setFacilities(false);
      setManage(false);
      setRules(false);
      setEmployee(false);
      setAttendance(!attendance);
    }
  };

  const { logout } = AdminAuthentication();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
        }}
      >
        <Drawer variant="permanent" open={open}>
          <Box
            height="100vh"
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#f7f7f7",
              border: "none",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "max-content",
                mt: "35px",
              }}
            >
              <LogoIcon fill="#40424E" />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                background: "#f7f7f7",
                zIndex: 100,
              }}
            >
              <List sx={{ padding: "0", mt: "40px" }}>
                <Link
                  style={{ ...sidebarLinks, margin: 0 }}
                  to="/dashboard"
                  onClick={toggleHtmlOverflow}
                >
                  <DashboardIcon />
                  Dashboard
                </Link>

                <Link
                  style={sidebarLinks}
                  selected={selectedIndex === 3}
                  onClick={(event) => handleListItemClick(event, 4)}
                >
                  <EmployeeIcon />
                  Employee
                </Link>

                {/* Sub Menu Mange Section start  */}
                {selectedIndex === 4 && employee && (
                  <Box
                    sx={{
                      display: open === false ? "none" : "",
                      a: {
                        display: "block",
                        textDecoration: "none",
                        color: "var(--secondary-color)",
                        // paddingLeft: "30px",
                        marginBottom: 0.2,
                        fontWeight: 600,
                      },
                    }}
                  >
                    <Box sx={{ opacity: open ? 1 : 0 }}>
                      <NavLink
                        to="/dashboard/employee"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Current Employee
                        </Typography>
                      </NavLink>
                      <NavLink
                        to="/dashboard/exemployee"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Ex-Employee
                        </Typography>
                      </NavLink>
                    </Box>
                  </Box>
                )}

                {user?.user?.role !== "manager" && (
                  <Link
                    style={sidebarLinks}
                    to="/dashboard/officeSalary"
                    onClick={toggleHtmlOverflow}
                  >
                    <SalaryIcon />
                    Salary
                  </Link>
                )}

                <Link
                  style={sidebarLinks}
                  to="/dashboard/leave"
                  onClick={toggleHtmlOverflow}
                >
                  <DepartmentIcon style={{ fill: "#40424E" }} />
                  Leave
                </Link>

                <Link
                  style={sidebarLinks}
                  to="/dashboard/pendingOperations"
                  onClick={toggleHtmlOverflow}
                >
                  <PendingActionsIcon style={{ fill: "#40424E" }} />
                  Pending Operations
                </Link>

                <Link
                  // onClick={(event) => handleListItemClick(event, 5)}
                  to="/dashboard/attendance"
                  style={{ ...sidebarLinks, margin: 0 }}
                  onClick={toggleHtmlOverflow}
                >
                  <DepartmentIcon style={{ fill: "#40424E" }} />
                  Attendance
                </Link>
                {selectedIndex === 5 && attendance && (
                  <Box
                    sx={{
                      display: open === false ? "none" : "",
                      a: {
                        display: "block",
                        textDecoration: "none",
                        color: "var(--secondary-color)",
                        // paddingLeft: "30px",
                        marginBottom: 0.2,
                        fontWeight: 600,
                      },
                    }}
                  >
                    <Box sx={{ opacity: open ? 1 : 0 }}>
                      <NavLink
                        to="/dashboard/attendance"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Regular Attendance
                        </Typography>
                      </NavLink>
                      <NavLink
                        to="/dashboard/attendance"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Attendance Request
                        </Typography>
                      </NavLink>
                      <NavLink
                        to="/dashboard/attendance"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Work From Home
                        </Typography>
                      </NavLink>
                      <NavLink
                        to="/dashboard/attendance"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Field Work
                        </Typography>
                      </NavLink>
                    </Box>
                  </Box>
                )}
                <Link
                  style={sidebarLinks}
                  selected={selectedIndex === 1}
                  onClick={(event) => handleListItemClick(event, 1)}
                  // onClick={toggleHtmlOverflow}
                  // to="/dashboard/roster"
                >
                  <ManageIcon />
                  Manage
                </Link>

                {/* Sub Menu Mange Section start  */}
                {selectedIndex === 1 && manage && (
                  <Box
                    sx={{
                      display: open === false ? "none" : "",
                      a: {
                        display: "block",
                        textDecoration: "none",
                        color: "var(--secondary-color)",
                        // paddingLeft: "30px",
                        marginBottom: 0.2,
                        fontWeight: 600,
                      },
                    }}
                  >
                    <Box sx={{ opacity: open ? 1 : 0 }}>
                      <NavLink
                        to="/dashboard/roster"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Roaster
                        </Typography>
                      </NavLink>
                      <NavLink
                        to="/dashboard/updateShifts"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Update Shifts
                        </Typography>
                      </NavLink>
                      <NavLink
                        to="/dashboard/department"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Department
                        </Typography>
                      </NavLink>
                      <NavLink
                        to="/dashboard/office"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Office
                        </Typography>
                      </NavLink>
                      <NavLink
                        to="/dashboard/notice"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Notice
                        </Typography>
                      </NavLink>
                      <NavLink
                        to="/dashboard/notice"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Inventory
                        </Typography>
                      </NavLink>
                      <NavLink
                        to="/dashboard/holiday"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Holiday
                        </Typography>
                      </NavLink>
                    </Box>
                  </Box>
                )}

                <Link
                  style={sidebarLinks}
                  // to="/dashboard/roster"
                  selected={selectedIndex === 3}
                  onClick={(event) => handleListItemClick(event, 3)}
                  // onClick={toggleHtmlOverflow}
                >
                  <AlignHorizontalRightIcon />
                  Rules
                </Link>

                {/* Sub Menu Mange Section start  */}
                {selectedIndex === 3 && rules && (
                  <Box
                    sx={{
                      display: open === false ? "none" : "",
                      a: {
                        display: "block",
                        textDecoration: "none",
                        color: "var(--secondary-color)",
                        // paddingLeft: "30px",
                        marginBottom: 0.2,
                        fontWeight: 600,
                      },
                    }}
                  >
                    <Box sx={{ opacity: open ? 1 : 0 }}>
                      <NavLink
                        to="/dashboard/absentRules"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Absent Rules
                        </Typography>
                      </NavLink>
                      <NavLink
                        to="/dashboard/lateCountRules"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Late Count Rules
                        </Typography>
                      </NavLink>
                    </Box>
                  </Box>
                )}

                <Link
                  style={sidebarLinks}
                  // to="/dashboard/roster"
                  selected={selectedIndex === 2}
                  onClick={(event) => handleListItemClick(event, 2)}
                  // onClick={toggleHtmlOverflow}
                >
                  <ManageIcon />
                  Facilities
                </Link>

                {selectedIndex === 2 && facilities && (
                  <Box
                    sx={{
                      display: open === false ? "none" : "",
                      a: {
                        display: "block",
                        textDecoration: "none",
                        color: "var(--secondary-color)",
                        // paddingLeft: "30px",
                        marginBottom: 0.2,
                        fontWeight: 600,
                      },
                    }}
                  >
                    <Box sx={{ opacity: open ? 1 : 0 }}>
                      <NavLink
                        to="/dashboard/loan"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Loan
                        </Typography>
                      </NavLink>
                      <NavLink
                        to="/dashboard/advanceSalary"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Advance Salary
                        </Typography>
                      </NavLink>
                      <NavLink
                        to="/dashboard/providentFund"
                        style={{
                          display: "block",
                          textDecoration: "none",
                        }}
                        onClick={toggleHtmlOverflow}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: 12,
                            py: 1,
                            px: 1,
                            fontWeight: 500,
                          }}
                        >
                          Provident Fund
                        </Typography>
                      </NavLink>
                    </Box>
                  </Box>
                )}

                <Link
                  style={sidebarLinks}
                  to="/dashboard/overTime"
                  onClick={toggleHtmlOverflow}
                >
                  <OvertimeIcon style={{ marginLeft: "-5px" }} />
                  Overtime
                </Link>

                <Link
                  style={{ ...sidebarLinks, margin: 0 }}
                  to="/dashboard/activityLog"
                  onClick={toggleHtmlOverflow}
                >
                  <ActivityIcon />
                  Activiy Log
                </Link>

                <Link
                  style={{ ...sidebarLinks, marginBottom: "0px" }}
                  onClick={logout}
                >
                  <LogoutIcon />
                  Logout
                </Link>
              </List>
            </Box>

            <Box
              sx={{
                position: "absolute",
                bottom: "30px",
                left: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Box mt={1}>
                  <UserIcon />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "14px" }}>
                    {user?.user?.firstname}
                    {user?.user?.lastname}
                  </Typography>
                  <Typography sx={{ fontSize: "13px" }}>
                    {user?.user?.companyName}
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{ fontSize: "12px", textAlign: "center", mt: "20px" }}
              >
                © Copyright Fly Far Tech
              </Typography>
            </Box>
          </Box>
        </Drawer>

        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              bgcolor: "var(--primary-color)",
              borderRadius: "0px 0px 50px 50px",
              width: "100%",
            }}
          >
            <Header open={open} toggleHtmlOverflow={toggleHtmlOverflow} />
          </Box>

          <Box
            sx={{
              transform: open ? "translate(10%)" : "translate(0%)",
              transition: "0.5s ease-in-out",
              marginTop: "-150px",
            }}
          >
            <Box
              sx={{
                width: open ? "110%" : "90%",
                margin: "0 auto",
              }}
            >
              <Box
                sx={{
                  padding: "35px 40px",
                  bgcolor: "white",
                  borderRadius: "30px 30px 0px 0px",
                  minHeight: "90vh",
                }}
              >
                <Box sx={{ overflowY: "scroll", maxHeight: "90vh" }}>
                  <Outlet></Outlet>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardSidebar;
