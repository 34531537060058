import { createTheme } from "@mui/material/styles";

const commonBtnStyle = (bgColor, color = "white") => {
  return {
    fontSize: "12px",
    color: color,
    backgroundColor: bgColor,
    ":hover": { backgroundColor: bgColor },
    padding: "5px 8px",
    borderRadius: "4px",
    textTransform: "capitalize",
  };
};

export const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: { "& span": { fontSize: "14px" }, "& p": { fontSize: "14px" } },
        subtitle1: {
          color: "var(--text-dark)",
          fontSize: "1.5rem",
          fontWeight: 600,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "darkBtn" },
          style: { ...commonBtnStyle("var(--text-medium)") },
        },
        {
          props: { variant: "lightBtn" },
          style: {
            ...commonBtnStyle("#EFF2F5", "var(--text-medium)"),
          },
        },
        {
          props: { variant: "primaryBtn" },
          style: {
            ...commonBtnStyle("var(--primary-color)"),
          },
        },
        {
          props: { variant: "disabledBtn" },
          style: {
            ...commonBtnStyle("#bbb", "#777"),
            pointerEvents: "none",
            opacity: 0.7,
          },
        },
      ],
    },
  },
});
