import React, { createContext, useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userData = secureLocalStorage.getItem("admin-info");

    if (userData) {
      setUser(userData);
    }
  }, []);

  const saveUser = (newUser) => {
    secureLocalStorage.setItem("admin-info", newUser);
    setUser(newUser);
  };

  return (
    <AuthContext.Provider value={{ user, saveUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
