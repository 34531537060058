import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Switch,
  Dialog,
  Tabs,
  Tab,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import APILoader from "../../components/APILoader/APILoader";
import Swal from "sweetalert2";
import moment from "moment";
import EmployeeShifts from "./EmployeeShifts";
import { CustomZoomTransition } from "../../components/CustomZoomTransition";
import { useQuery } from "react-query";
import Select from "react-select";
import { filterSelectStyle } from "../../utility/styles";
import { useFetchAPI } from "../../hooks/useFetchAPI";

const headers = [
  "Name",
  "Status",
  "Designations",
  "Join date",
  "Salary",
  "Leave",
  "Personal Contact",
  "Action",
  "Active",
  "View Shifts",
];

const Employee = () => {
  const { getFetcher } = useFetchAPI();
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("admin-info");
  const [deptId, setDeptId] = useState("");
  const [employeeDept, setEmployeeDept] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const token = user?.accessToken;

  const handleUpdate = (data) => {
    const dataToSend = {
      data,
    };

    navigate("/dashboard/employeeUpdate", { state: { data: dataToSend } });
  };

  const handleMasterCheckboxChange = (event) => {
    let isChecked = event.target.checked;

    if (allCheck === false) {
      isChecked = true;
      setAllCheck(true);
    } else {
      setAllCheck(false);
      isChecked = false;
    }

    if (isChecked) {
      const allselectedEmployee = employeeDept.map((data) => ({
        id: data.id,
        firstname: data.firstname,
        lastname: data.lastname,
        deptName: data.deptName,
        branchId: data.branchId,
        deptId: data.deptId,
      }));
      setSelectedRows(allselectedEmployee);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSingleCheckboxChange = (
    event,
    id,
    firstname,
    lastname,
    deptName,
    branchId,
    deptId
  ) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedRows((prevselectedEmployee) => [
        ...prevselectedEmployee,
        { id, firstname, lastname, deptName, branchId, deptId },
      ]);
    } else {
      setSelectedRows((prevselectedEmployee) =>
        prevselectedEmployee.filter((row) => row.id !== id)
      );
    }
  };

  const { data: departments, status } = useQuery({
    queryKey: ["all-departments"],
    queryFn: () => getFetcher(`departments`),
    onSuccess: (data) => {
      if (data?.data?.length > 0) {
        setDeptId(data.data[0].id);
      }
    },
    refetchOnMount: "always",
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  const { data: employee, status: employeeStatus } = useQuery({
    queryKey: ["employee-by-deptId", deptId],
    queryFn: () => getFetcher(`employees?deptId=${deptId}`),
    enabled: !!deptId,
  });

  if (status === "loading" || employeeStatus === "loading") return <>Loading</>;

  const handleSingleEmployee = (id) => {
    navigate(`/dashboard/employeeDtails`, { state: { employeeId: id } });
  };

  const handleStatusChange = (id) => {
    const singleUpdated = employeeDept.find((emp) => emp.id === id);

    try {
      Swal.fire({
        icon: "warning",
        title: `Are you sure you want to ${
          singleUpdated.isActive ? "inactive" : "active"
        } employee`,
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Ok",
      }).then(async function (res) {
        if (res.isConfirmed) {
          await fetch(
            `https://flyfarerp-413411.de.r.appspot.com/api/company/employees/${id}/update-status`,
            {
              method: "PATCH",
              body: JSON.stringify({
                status: singleUpdated.isActive ? "inactive" : "active",
              }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.success) {
                Swal.fire({
                  icon: "success",
                  title: data.message,
                  confirmButtonColor: "var(--primary-color)",
                  confirmButtonText: "Ok",
                }).then(function () {
                  window.location.reload();
                });
              }
            });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const selectOptions = departments?.data
    ?.slice(7, departments?.data.length)
    .map((br) => ({
      label: br?.deptName,
      value: br?.id,
    }));

  const handleSelectChange = (option) => {
    setDeptId(option.value);
  };

  return (
    <Box sx={{ position: "relative", height: "100vh", overflowY: "auto" }}>
      {/*------------- Page Title -------------*/}

      {departments?.data.length > 0 ? (
        <Box sx={{ paddingBottom: "50px" }}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1">Employee List</Typography>
          </Box>

          {/* <Box
            className="justify_between align_center"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "& .MuiTabs-root": { minHeight: "31px" },
            }}
          >
            <Tabs
              value={deptId}
              onChange={(_, newValue) => {
                setDeptId(departments.data[newValue].id);
              }}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                height: "36px",
                width: "75%",
                "& .MuiTabs-indicator": { display: "none" },
                // "& .MuiTabs-root": { minHeight: "36px" },
                "& .Mui-selected": { color: "white" },
                "& .MuiTabScrollButton-root": {
                  color: "var(--text-medium)",
                  bgcolor: "#EFF2F5",
                  width: "16px",
                  ml: 1,
                },
                "& .MuiTabScrollButton-root:first-of-type": {
                  mr: 1,
                  ml: 0,
                },
                // "data"
                height: "31px",
              }}
            >
              {departments?.data.map((department, i) => (
                <Tab
                  key={i}
                  label={department?.deptName}
                  variant={
                    deptId === department?.id ? "primaryBtn" : "lightBtn"
                  }
                  sx={{
                    mr: 1,
                    fontSize: "12px",
                    bgcolor:
                      deptId === department?.id
                        ? "var(--primary-color)"
                        : "#EFF2F5",
                    color:
                      deptId === department?.id
                        ? "white"
                        : "var(--text-medium)",
                    "&:hover": {
                      bgcolor:
                        deptId === department?.id
                          ? "var(--primary-color)"
                          : "#EFF2F5",
                    },
                    padding: "4px 8px",
                    borderRadius: "4px",
                    textTransform: "capitalize",
                    minHeight: "31px",
                    opacity: 1,
                  }}
                />
              ))}
            </Tabs>

            <Box className="justify_between align_center" sx={{ gap: 2 }}>
              <Button
                variant="primaryBtn"
                sx={{
                  visibility: selectedRows.length > 0 ? "visible" : "hidden",
                }}
                onClick={() => {
                  navigate("/dashboard/employee/assignShift", {
                    state: { selectedRows },
                  });
                }}
              >
                Assign Shifts
              </Button>

              <Button
                variant="darkBtn"
                onClick={() => navigate("/dashboard/employee/addEmployee")}
              >
                Add Employee
              </Button>
            </Box>
          </Box> */}

          <Box className="justify_between">
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                flexWrap: "wrap",
                width: "75%",
              }}
            >
              {departments?.data?.slice(0, 7).map((department, i) => (
                <Button
                  key={i}
                  variant={
                    deptId === department?.id ? "primaryBtn" : "lightBtn"
                  }
                  onClick={() => setDeptId(department.id)}
                >
                  {department?.deptName}
                </Button>
              ))}

              <Select
                onChange={handleSelectChange}
                styles={filterSelectStyle(deptId)}
                classNamePrefix="select"
                isSearchable={false}
                options={selectOptions}
                placeholder={
                  <Typography sx={filterSelectStyle(deptId).labelText} noWrap>
                    Select More Department
                  </Typography>
                }
                value={
                  selectOptions.find((option) => option.value === deptId) ||
                  null
                }
              />
            </Box>

            <Box className="justify_between align_center" sx={{ gap: 2 }}>
              <Button
                variant="primaryBtn"
                sx={{
                  visibility: selectedRows.length > 0 ? "visible" : "hidden",
                }}
                onClick={() => {
                  navigate("/dashboard/employee/assignShift", {
                    state: { selectedRows },
                  });
                }}
              >
                Assign Shifts
              </Button>

              <Button
                variant="darkBtn"
                onClick={() => navigate("/dashboard/employee/addEmployee")}
              >
                Add Employee
              </Button>
            </Box>
          </Box>

          {/*------------- Page Content -------------*/}
          <Box mt={3}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 1450 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={allCheck}
                        onChange={handleMasterCheckboxChange}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "Var(--primary-color)",
                          },
                        }}
                      />
                    </TableCell>
                    {headers.map((header, i) => (
                      <TableCell key={i}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employee?.data.map((data, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            checked={selectedRows.some(
                              (row) => row.id === data.id
                            )}
                            onChange={(event) =>
                              handleSingleCheckboxChange(
                                event,
                                data.id,
                                data.firstname,
                                data.lastname,
                                data.deptName,
                                data.branchId,
                                data.deptId
                              )
                            }
                            sx={{
                              "& .MuiSvgIcon-root": {
                                color: "Var(--primary-color)",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="primaryBtn"
                            sx={{ fontSize: "13px", width: "90%" }}
                            onClick={() => handleSingleEmployee(data?.id)}
                          >
                            {data?.firstname} {data?.lastname}
                          </Button>
                        </TableCell>
                        <TableCell> {data?.status} </TableCell>
                        <TableCell> {data?.designation} </TableCell>
                        <TableCell> {data?.joiningDate} </TableCell>
                        <TableCell> {data?.grossSalary} </TableCell>
                        <TableCell> {data?.casualLeave} </TableCell>
                        <TableCell> {data?.personalPhone} </TableCell>
                        <TableCell>
                          <Button
                            variant="primaryBtn"
                            onClick={() => handleUpdate(data)}
                          >
                            Update
                          </Button>
                        </TableCell>

                        <TableCell>
                          <Switch
                            checked={data?.isActive}
                            onChange={() => handleStatusChange(data.id)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </TableCell>

                        <TableCell>
                          <Button
                            variant="primaryBtn"
                            onClick={() => {
                              setOpen(true);
                              setCurrentData(data);
                            }}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      ) : (
        <Box sx={{ height: "calc(100vh - 200px)" }}>
          <APILoader />
        </Box>
      )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={CustomZoomTransition}
        maxWidth="md"
        fullWidth
      >
        <Box p={3} minHeight={"460px"}>
          <EmployeeShifts currentData={currentData} />
        </Box>
      </Dialog>
    </Box>
  );
};

export default Employee;
