import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Zoom,
} from "@mui/material";
import moment from "moment/moment";
import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const roasterHeader = [
  "Name",
  "Email",
  "Designation",
  "Branch Name",
  "Department Name",
];

const updateShiftHeader = [
  "Shift Count",
  "Roster Name",
  "Check In",
  "Check Out",
  "Shift Location",
];

const initialBody = {
  employeeShiftDays: [
    {
      employeeId: "",
      days: [],
    },
  ],
  status: "",
  activeNow: false,
};

const PendingShiftAssign = ({ singleReq, setIsRefetch }) => {
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [allCheck, setAllCheck] = useState(false);
  const [activeNow, setActiveNow] = useState("");
  const [reqBody, setReqBody] = useState(initialBody);

  // console.log(currentData)

  const handleSelectAllDays = (e) => {
    const isChecked = e.target.checked;
    setAllCheck(isChecked);

    if (isChecked) {
      setReqBody({
        ...reqBody,
        employeeShiftDays: [
          {
            employeeId: currentData.employeeId,
            days: currentData?.allDays?.map((day) => day?.applicableDay),
          },
        ],
      });
    } else {
      setReqBody({
        ...reqBody,
        employeeShiftDays: [
          {
            employeeId: "",
            days: [],
          },
        ],
      });
    }
  };

  const handleSelectSingleDays = (applicableDay) => {
    const arr = [...reqBody.employeeShiftDays[0].days, applicableDay];

    if (arr.length === currentData?.allDays.length) {
      setAllCheck(true);
    } else {
      setAllCheck(false);
    }

    if (reqBody.employeeShiftDays[0].days.includes(applicableDay)) {
      setReqBody({
        ...reqBody,
        employeeShiftDays: [
          {
            employeeId: currentData.employeeId,
            days: reqBody.employeeShiftDays[0].days.filter(
              (day) => day !== applicableDay
            ),
          },
        ],
      });
    } else {
      setReqBody({
        ...reqBody,
        employeeShiftDays: [
          {
            employeeId: currentData.employeeId,
            days: arr,
          },
        ],
      });
    }
  };

  // console.log(reqBody);
  // console.log(currentData);

  const handleAcceptReject = async (type) => {
    setIsLoading(true);
    // const url = "http://192.168.1.103:5000/api/company/shifts/approval/by-day";
    const url =
      "https://flyfarerp-413411.de.r.appspot.com/api/company/shifts/approval/by-day";
    try {
      const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify({
          ...reqBody,
          status: type,
          activeNow,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data?.success === true) {
        setIsLoading(false);
        setOpen(false);
        setReqBody(initialBody);
        // setIsRefetch(true);
        Swal.fire({
          icon: "success",
          title: data?.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          // navigate("/dashboard/roster");
          // window.location.reload();
          setIsRefetch(true);
        });
      } else {
        setOpen(false);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: data?.error?.message || "Something went wrong",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Try again",
        });
      }
    } catch (err) {
      setOpen(false);
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: err.message || "Something went wrong",
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Try again",
      });
    } finally {
      setIsRefetch(false);
    }
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {roasterHeader.map((header, i) => (
                <TableCell key={i}>{header}</TableCell>
              ))}

              {user?.user?.role !== "manager" && (
                <TableCell sx={{ textAlign: "center" }}>
                  Pending Shift
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {singleReq?.map((req, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>
                    {req?.firstname} {req?.lastname}
                  </TableCell>
                  <TableCell>{req?.email}</TableCell>
                  <TableCell>{req?.designation}</TableCell>
                  <TableCell>{req?.branchName}</TableCell>
                  <TableCell>{req?.deptName}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <span
                      onClick={() => {
                        setOpen(true);
                        setCurrentData(req);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      View
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(!open);
          setReqBody(initialBody);
        }}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <Box
          py={3}
          px={4}
          sx={{
            "& .MuiTableCell-root": {
              padding: "0 !important",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography sx={{ fontSize: "18px" }}>
              {currentData?.firstname} {currentData?.lastname} Pending Shifts
            </Typography>
            <Box
              className="input-container"
              style={{
                width: "35%",
                borderColor: "white",
                marginTop: "0",
                backgroundColor: "var(--primary-color)",
                padding: "0 10px",
                borderRadius: "5px",
              }}
            >
              <label
                htmlFor="activeType"
                className="input-label"
                style={{ width: "65%", cursor: "auto", color: "white" }}
              >
                Activation Type :
              </label>
              <select
                value={activeNow}
                onChange={(e) =>
                  setActiveNow(e.target.value === "true" ? true : false)
                }
                id="activeType"
                className="input-field"
                style={{
                  padding: "0",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                }}
              >
                <option value={""}>Select Activation Type</option>
                <option value={false}>Active Later</option>
                <option value={true}>Active Now</option>
              </select>
            </Box>
          </Box>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "20%" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            allCheck &&
                            currentData?.allDays.length ===
                              reqBody.employeeShiftDays[0].days.length
                          }
                          onChange={handleSelectAllDays}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              color: "Var(--primary-color)",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: "14px" }}>
                          Select All Days
                        </Typography>
                      }
                    />
                  </TableCell>
                  {updateShiftHeader.map((header, i) => (
                    <TableCell key={i}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentData?.allDays?.map((day, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={reqBody.employeeShiftDays[0].days.includes(
                                day.applicableDay
                              )}
                              onChange={() =>
                                handleSelectSingleDays(day?.applicableDay)
                              }
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  color: "Var(--primary-color)",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography sx={{ fontSize: "14px" }}>
                              {day?.applicableDay}
                            </Typography>
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {day?.shifts?.map((shift, ind, arr) => {
                          return (
                            <span
                              key={ind}
                              style={{
                                display: ind === 0 ? "block" : "none",
                                borderTop:
                                  arr.length > 0 &&
                                  ind !== 0 &&
                                  "1px solid var(--border-color)",
                              }}
                            >
                              {shift?.dayState !== "weekend" &&
                                day?.shifts.length}
                            </span>
                          );
                        })}
                      </TableCell>
                      <TableCell>
                        {day?.shifts?.map((shift, ind, arr) => {
                          return (
                            <span
                              key={ind}
                              style={{
                                display: "block",
                                borderTop:
                                  arr.length > 0 &&
                                  ind !== 0 &&
                                  "1px solid var(--border-color)",
                              }}
                            >
                              {shift?.roaster?.roasterName}

                              {shift?.dayState === "weekend" && shift?.dayState}
                            </span>
                          );
                        })}
                      </TableCell>
                      <TableCell>
                        {day?.shifts?.map((shift, ind, arr) => {
                          return (
                            <span
                              key={ind}
                              style={{
                                display: "block",
                                borderTop:
                                  arr.length > 0 &&
                                  ind !== 0 &&
                                  "1px solid var(--border-color)",
                              }}
                            >
                              {shift?.roaster?.checkIn && (
                                <>
                                  {moment(
                                    shift?.roaster?.checkIn,
                                    "hh:mm:ss"
                                  ).format("hh:mm:ss A")}
                                </>
                              )}
                            </span>
                          );
                        })}
                      </TableCell>
                      <TableCell>
                        {day?.shifts?.map((shift, ind, arr) => {
                          return (
                            <span
                              key={ind}
                              style={{
                                display: "block",
                                borderTop:
                                  arr.length > 0 &&
                                  ind !== 0 &&
                                  "1px solid var(--border-color)",
                              }}
                            >
                              {shift?.roaster?.checkOut && (
                                <>
                                  {moment(
                                    shift?.roaster?.checkOut,
                                    "hh:mm:ss"
                                  ).format("hh:mm:ss A")}
                                </>
                              )}
                            </span>
                          );
                        })}
                      </TableCell>
                      <TableCell>
                        {day?.shifts?.map((shift, ind, arr) => {
                          return (
                            <span
                              key={ind}
                              style={{
                                display: "block",
                                borderTop:
                                  arr.length > 0 &&
                                  ind !== 0 &&
                                  "1px solid var(--border-color)",
                              }}
                            >
                              {shift?.shiftLocation}
                            </span>
                          );
                        })}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: "15px",
              mt: 3,
            }}
          >
            <Button
              disabled={isLoading}
              sx={{
                background: "var(--primary-color)",
                color: "#ffffff",
                textTransform: "capitalize",
                "&:hover": { bgcolor: "var(--primary-color)" },
              }}
              onClick={() => handleAcceptReject("active")}
            >
              Accept
            </Button>
            <Button
              disabled={isLoading}
              sx={{
                background: "var(--primary-color)",
                color: "#ffffff",
                textTransform: "capitalize",
                "&:hover": { bgcolor: "var(--primary-color)" },
              }}
              onClick={() => handleAcceptReject("rejected")}
            >
              Reject
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default PendingShiftAssign;
