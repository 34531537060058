import {
  Box,
  Button,
  Dialog,
  Grow,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as LeftArrowIcon } from "../../assets/svg/leftArrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/svg/rightArrow.svg";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { submenuButton } from "../../utility/styles";
import SingleAttendenceDetails from "./SingleAttendenceDetails";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DeptAttendencePDF from "../../components/PDF/DeptAttendencePDF";
import APILoader from "../../components/APILoader/APILoader";
import SingleOfficeXL from "./components/SingleOfficeXL";
import { useQuery } from "react-query";
import { useFetchAPI } from "../../hooks/useFetchAPI";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const headers = [
  "Employee Name",

  "Designation",
  "Ratio",
  "Check In",
  "Check In Status",
  "Check In Remarks",
  "Check Out",
  "Check Out Status ",
  "Check Out Remarks ",
  "Details",
];

const subLists = [
  {
    id: 1,
    name: "All",
    query: "all",
  },
  {
    id: 2,
    name: "Check In",
    query: "",
  },
  {
    id: 2,
    name: "Check In Late",
    query: "",
  },
  {
    id: 2,
    name: "Check In Pending",
    query: "",
  },
  {
    id: 2,
    name: "Check Out Early",
    query: "on-time",
  },
  {
    id: 3,
    name: "Check Out",
    query: "late",
  },
  {
    id: 4,
    name: "Weekend",
    query: null,
  },
  {
    id: 7,
    name: "On Field",
    query: null,
  },
  {
    id: 7,
    name: "Leave",
    query: null,
  },
];

function getCurrentMonth(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const monthAbbreviation = months[monthIndex];

  return `${day} ${monthAbbreviation}, ${year}`;
}

const SingleOfficeAttendence = () => {
  const { getFetcher } = useFetchAPI();
  const navigate = useNavigate();
  const location = useLocation();
  const locationData = location.state;
  const [currentDate, setCurrentDate] = useState(new Date());
  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;
  const [attendanceData, setAttendanceData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [employeeId, setEmployeeId] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [selectedDept, setSelectedDept] = useState({
    id: "",
    deptName: "",
  });
  const [initialData, setInitialData] = useState([]);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
  const [openDownloadOption, setOpenDownloadOption] = useState(false);
  const [csvAttendanceData, setCsvAttendanceData] = useState([]);

  const { data: departments, status } = useQuery({
    queryKey: ["all-departments"],
    queryFn: () => getFetcher(`departments`),
  });

  const handleNextDate = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 1);

    const currentDateObj = new Date(); // Current date
    // Reset time parts to compare dates accurately
    currentDateObj.setHours(0, 0, 0, 0);

    const tomorrow = new Date(currentDateObj);
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (newDate <= tomorrow) {
      setCurrentDate(newDate);
      // filterData(newDate);
    } else {
      console.log("Cannot go beyond the current date.");
    }
  };

  const handlePreviousDate = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 1);
    setCurrentDate(newDate);
    // filterData(newDate);
  };

  const handleOpen = (employeId, employeeName) => {
    setOpen(true);
    setEmployeeId(employeId);
    setEmployeeName(employeeName);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://flyfarerp-413411.de.r.appspot.com/api/company/attendances/monthly-report?branchId=${
          locationData?.branchId
        }${selectedDept?.id && "&deptId=" + selectedDept?.id}&month=${
          currentDate.getMonth() + 1
        }&year=${currentDate.getFullYear()}`;

        // const url = `http://192.168.1.103:5000/api/company/attendances/monthly-report?branchId=${
        //   locationData?.branchId
        // }${selectedDept?.id && "&deptId=" + selectedDept?.id}&month=${
        //   currentDate.getMonth() + 1
        // }&year=${currentDate.getFullYear()}`;

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch attendance data");
        }

        const data = await response.json();
        // console.log(data);
        setCsvAttendanceData(data.data);
      } catch (error) {
        console.error("Error fetching attendance data:", error.message);
      }
    };

    fetchData();
  }, [currentDate, locationData, token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://flyfarerp-413411.de.r.appspot.com/api/company/attendances/day-summary?branchId=${
          locationData?.branchId
        }&date=${moment(currentDate).format("YYYY-MM-DD")}`;

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch attendance data");
        }

        const data = await response.json();
        // console.log(data);
        setAttendanceData(data.data);
        setInitialData(data.data);
      } catch (error) {
        console.error("Error fetching attendance data:", error.message);
      }
    };

    fetchData();
  }, [currentDate, locationData, token]);

  const handleSingleOfficeStaff = (employeeId, deptName, employeeName) => {
    navigate(`/dashboard/singleStaffAttendance`, {
      state: {
        employeeId: employeeId,
        date: currentDate,
        deptName: deptName,
        employeeName: employeeName,
      },
    });
  };

  const handleDeptChange = (event) => {
    setSelectedDept({
      id: event.target.value,
      deptName:
        departments?.data.find((dept) => dept.id === event.target.value)
          ?.deptName || "",
    });
  };

  const filteredAttendanceData = selectedDept.id
    ? attendanceData.filter((data) => data.deptId === selectedDept.id)
    : attendanceData;

  const csvData = selectedDept.id
    ? csvAttendanceData.filter((data) => data.deptId === selectedDept.id)
    : csvAttendanceData;

  const handleFilterByStatus = (query, i) => {
    if (query === "all") {
      setAttendanceData(initialData);
      setSelectedButtonIndex(0);
    } else {
      setAttendanceData(
        initialData.filter((data) => {
          if (i === 1) {
            return data.inTimeStatus;
          } else if (i === 2) {
            return data.outTimeStatus;
          } else if (i > 2 && i < 6) {
            return data.inTimeStatus === query;
          } else {
            return data.outTimeStatus === query;
          }
        })
      );
      setSelectedButtonIndex(i);
    }
  };

  // console.log(csvData);
  return (
    <Box>
      {csvAttendanceData.length &&
      (departments?.data.length > 0 || attendanceData.length > 0) ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="title" className="page-title" sx={{ flex: 1 }}>
              {selectedDept.deptName ? selectedDept.deptName : "All"} Employees
              Attendance "{getCurrentMonth(currentDate)}"
            </Typography>

            <Box className="date-change-container" sx={{ flex: "none" }}>
              <button
                className="previous-next-button"
                onClick={handlePreviousDate}
                sx={{ width: "34px", height: "22px" }}
              >
                <LeftArrowIcon />
              </button>
              <button
                className="previous-next-button"
                onClick={handleNextDate}
                sx={{ width: "34px", height: "22px" }}
              >
                <RightArrowIcon />
              </button>
            </Box>
          </Box>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: "20px", mt: 2 }}
          >
            <Button
              onClick={() => setOpenDownloadOption(!openDownloadOption)}
              sx={{
                bgcolor: "var(--primary-color)",
                color: "white",
                textTransform: "capitalize",
                ":hover": {
                  bgcolor: "var(--primary-color)",
                },
                fontSize: "12px",
              }}
            >
              Download Attendance Summary
            </Button>

            <Grow in={openDownloadOption}>
              <Box>
                <PDFDownloadLink
                  document={
                    <DeptAttendencePDF
                      reportData={filteredAttendanceData}
                      selectedDept={selectedDept}
                    />
                  }
                  fileName={`${
                    selectedDept.deptName ? selectedDept.deptName : "All"
                  } Employees Attendence Report on ${
                    filteredAttendanceData[0]?.attendanceDate
                  }.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "12px",
                          pt: 0.5,
                          pb: "2px",
                          borderBottom: "1px solid var(--border-color)",
                        }}
                      >
                        <Typography sx={{ fontSize: "12px", mt: "2px" }}>
                          Download as PDF
                        </Typography>
                        <SystemUpdateAltIcon
                          style={{
                            color: "var(--primary-color)",
                            width: "16px",
                          }}
                        />
                      </Box>
                    )
                  }
                </PDFDownloadLink>

                <SingleOfficeXL
                  fileName={
                    selectedDept?.id ? selectedDept.deptName : "All Employee"
                  }
                  csvData={csvData}
                />
              </Box>
            </Grow>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "8px", mt: 3 }}>
              {subLists.map((list, i) => (
                <Button
                  onClick={() => handleFilterByStatus(list.query, i)}
                  key={i}
                  sx={{
                    ...submenuButton,
                    ":hover": {
                      bgcolor: "var(--primary-color)",
                      color: "white",
                    },
                    bgcolor:
                      selectedButtonIndex === i
                        ? "var(--primary-color)"
                        : "#EFF2F5",
                    color:
                      selectedButtonIndex === i
                        ? "white"
                        : "var(--text-medium)",
                  }}

                  // variant={
                  //   deptId === department?.id ? "primaryBtn" : "lightBtn"
                  // }
                >
                  {list?.name}
                </Button>
              ))}
            </Box>

            <Box>
              <select
                type="text"
                name="deptId"
                id="deptId"
                required
                style={{
                  padding: "10px",
                  fontSize: "16px",
                  border: "none",
                  borderRadius: "5px",
                  background: "var(--primary-color)",
                  color: "white",
                  cursor: "pointer",
                  width: "100%",
                }}
                onChange={handleDeptChange}
              >
                <option value="" disabled selected>
                  Select Department Here
                </option>
                {departments?.data.map((data, index) => (
                  <option key={index} value={data.id}>
                    {data.deptName}
                  </option>
                ))}
                <option value="">All</option>
              </select>
            </Box>
          </Box>
          <Box mt={5}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 1450 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {headers.map((header, i) => (
                      <TableCell key={i}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredAttendanceData.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Button
                          sx={{
                            bgcolor: "var(--primary-color)",
                            color: "white",
                            fontSize: "12px",
                            width: "100%",
                            textTransform: "capitalize",
                            "&:hover": {
                              bgcolor: "var(--primary-color)",
                            },
                          }}
                          onClick={() =>
                            handleSingleOfficeStaff(
                              data?.employeeId,
                              data?.deptName,
                              data?.employeeName
                            )
                          }
                        >
                          {data?.employeeName}
                        </Button>
                      </TableCell>

                      <TableCell>{data?.designation}</TableCell>
                      <TableCell>
                        {data?.completeAttendances}/{data?.shiftCount}
                      </TableCell>
                      <TableCell>
                        {data?.inTime
                          ? moment(data.inTime, "HH:mm:ss").format("h:mm A")
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        <Button
                          sx={{
                            bgcolor:
                              data?.inTimeStatus === "on-time"
                                ? "green"
                                : data?.inTimeStatus === "early"
                                ? "blue"
                                : data?.inTimeStatus === "late"
                                ? "orange"
                                : "red",
                            color: "white",
                            fontSize: "12px",
                            width: "90%",
                            height: "25px",
                            cursor: "auto",
                            textTransform: "capitalize",
                            "&:hover": {
                              bgcolor:
                                data?.inTimeStatus === "on-time"
                                  ? "green"
                                  : data?.inTimeStatus === "early"
                                  ? "blue"
                                  : data?.inTimeStatus === "late"
                                  ? "orange"
                                  : "red",
                            },
                          }}
                        >
                          {data?.inTimeStatus || "Pending"}
                        </Button>
                      </TableCell>
                      <TableCell>
                        {data?.inTimeRemarks ? (
                          <Tooltip
                            TransitionComponent={Zoom}
                            arrow
                            title={
                              <span style={{ fontSize: "14px" }}>
                                {data?.inTimeRemarks}
                              </span>
                            }
                          >
                            {data?.inTimeRemarks?.slice(0, 20)}
                          </Tooltip>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell>
                        {data?.outTime
                          ? moment(data.outTime, "HH:mm:ss").format("h:mm A")
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        <Button
                          sx={{
                            bgcolor:
                              data?.outTimeStatus === "on-time"
                                ? "green"
                                : data?.outTimeStatus === "early"
                                ? "orange"
                                : "red",
                            color: "white",
                            fontSize: "12px",
                            width: "60%",
                            height: "25px",
                            cursor: "auto",
                            textTransform: "capitalize",
                            "&:hover": {
                              bgcolor:
                                data?.outTimeStatus === "on-time"
                                  ? "green"
                                  : data?.outTimeStatus === "early"
                                  ? "orange"
                                  : "red",
                            },
                          }}
                        >
                          {data?.outTimeStatus || "Pending"}
                        </Button>

                        {/* {data?.outTimeStatus || "N/A"} */}
                      </TableCell>

                      <TableCell>{data?.outTimeRemarks || "N/A"}</TableCell>

                      <TableCell>
                        <Button
                          sx={{
                            bgcolor: "var(--primary-color)",
                            color: "white",
                            fontSize: "12px",
                            width: "100%",
                            textTransform: "capitalize",
                            "&:hover": {
                              bgcolor: "var(--primary-color)",
                            },
                          }}
                          onClick={() => {
                            handleOpen(data?.employeeId, data?.employeeName);
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <Box sx={{ height: "calc(100vh - 200px)" }}>
          <APILoader />
        </Box>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <Box sx={{ p: 3, minHeight: "450px" }}>
          <SingleAttendenceDetails
            currentDate={currentDate}
            employeeId={employeeId}
            employeeName={employeeName}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export default SingleOfficeAttendence;
